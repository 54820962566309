import {Component, OnDestroy, OnInit} from '@angular/core'
import {RouterOutlet} from '@angular/router'
import {SettingStore} from '@core/store/setting.store'
import {SettingInterface} from '@interfaces'
import {SubSink} from 'subsink'
import {fadein, slideFadeinRight, slideFadeinUp, zoomFadein} from '../router-animations'

@Component({
    selector: 'layout-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    animations: [slideFadeinUp, slideFadeinRight, zoomFadein, fadein]
})
export class LayoutUserComponent implements OnInit, OnDestroy {

    public settings: SettingInterface
    private subs = new SubSink()

    constructor(
        private settingStore: SettingStore
    ) {
        this.settings = this.settingStore.get()
        this.subs.add(
            this.settingStore.settingUpdate$.subscribe((settings: SettingInterface) => {
                this.settings = settings
            })
        )
    }

    ngOnInit(): void {
        //
    }

    ngOnDestroy() {
        this.subs.unsubscribe()
    }

    routeAnimation(outlet: RouterOutlet, animation: string) {
        const mapAnimation = {
            'slide-fadein-up': 'slideFadeinUp',
            'slide-fadein-right': 'slideFadeinRight',
            fadein: 'zoomFadein',
            'zoom-fadein': 'fadein'
        }
        if (animation === mapAnimation[this.settings.routerAnimation]) {
            return outlet.isActivated && outlet.activatedRoute.routeConfig.path
        }
    }
}
