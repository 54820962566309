import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {AuthStore} from '@core/store/auth.store'
import {OrganizationStore} from '@core/store/organization.store'
import {WebStorageService} from 'ngx-web-storage'
import {Observable} from 'rxjs'

const requests = {
    login: () => '/auth/login',
    check: () => '/user/check',
    logout: () => '/user/logout'
}

const routes = {
    login: () => '/auth/login',
    register: () => '/auth/register',
    // forgetPassword: () => '/auth/forget-password',
    logout: () => '/user/common/logout',
    organizations: () => '/user/organizations'
}

@Injectable()
export class AuthService {

    routes = routes

    constructor(
        private http: HttpClient,
        private authStore: AuthStore,
        private organizationStore: OrganizationStore,
        private storage: WebStorageService
    ) {
        //
    }

    login(params): Observable<any> {
        return this.http.post(requests.login(), params)
    }

    check(): Observable<any> {
        return this.http.get(requests.check())
    }

    logout(): Observable<any> {
        return this.http.get(requests.logout())
    }

    async clear() {
        // this.storage.local.remove('accessToken')
        // this.storage.session.remove('organizationKey')
        await this.storage.local.clear() // tum local datalari siler
        await this.storage.session.clear() // tum session datalari siler
        await this.authStore.destroy()
        await this.organizationStore.destroy()
    }
}
