import {MenuInterface} from '@core/services/menu/menu.interface'

export const menuBuyer: Array<MenuInterface> = [
    {
        title: 'Dashboard-buyer',
        key: '__buyer_dashboard',
        icon: 'bi bi-house',
        children: [
            {
                title: 'Dashboard-buyer',
                key: '__buyer_dashboard_main',
                url: '/buyer/dashboard/main'
            },
            {
                title: 'Takvim',
                key: '__buyer_dashboard_calendar',
                url: '/buyer/dashboard/calendar'
            }
        ]
    },
    {
        title: 'İhale Ekle',
        key: '__buyer_bids_create',
        icon: 'bi bi-bookmark-plus',
        url: '/buyer/bids/create'
    },
    {
        title: 'İhale',
        key: '__buyer_bids',
        icon: 'bi bi-list',
        children: [
            {
                title: 'Devam Eden İhaleler',
                key: '__buyer_bids_list_stage_offer_active',
                url: '/buyer/bids',
                queryParams: {stage: 'offer_active'}
            },
            {
                title: 'Onay Bekleyen İhaleler',
                key: '__buyer_bids_list_deliveryChangeRequestStage',
                url: '/buyer/bids',
                queryParams: {deliveryChangeRequestStage: 'waiting'}
            },
            {
                title: 'Reddedilen İhaleler',
                key: '__buyer_bids_list_deliveryChangeRequestStage_rejected',
                url: '/buyer/bids',
                queryParams: {deliveryChangeRequestStage: 'rejected'}
            },
            {
                title: 'Tüm İhaleler',
                key: '__buyer_bids_list_all',
                url: '/buyer/bids'
            }
        ]
    },

    {
        title: 'Müşteri',
        key: '__buyer_customers',
        icon: 'bi bi-people',
        children: [
            {
                title: 'Müşteriler',
                key: '__buyer_customers_list',
                url: '/buyer/customers'
            },
            {
                title: 'Müşteri Ekle',
                key: '__buyer_customers_create',
                url: '/buyer/customers/create'
            }
        ]
    }
]
