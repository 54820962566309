import {Injectable} from '@angular/core'
import {NzMessageService} from 'ng-zorro-antd/message'
import {NzNotificationService} from 'ng-zorro-antd/notification'

@Injectable()
export class Notify {
    constructor(
        private nzNotificationService: NzNotificationService,
        private nzMessageService: NzMessageService
    ) {
    }

    // NzNotificationService
    s(t: any, c?: any, p?: any) {
        this.nzNotificationService.success(t, c, p)
    }

    i(t: any, c?: any, p?: any) {
        this.nzNotificationService.info(t, c, p)
    }

    w(t: any, c?: any, p?: any) {
        this.nzNotificationService.warning(t, c, p)
    }

    e(t: any, c?: any, p?: any) {
        this.nzNotificationService.error(t, c, p)
    }

    b(t: any, c?: any, p?: any) {
        this.nzNotificationService.blank(t, c, p)
    }

    // NzMessageService
    sm(t: any, p?: any) {
        this.nzMessageService.success(t, p)
    }

    im(t: any, p?: any) {
        this.nzMessageService.info(t, p)
    }

    wm(t: any, p?: any) {
        this.nzMessageService.warning(t, p)
    }

    em(t: any, p?: any) {
        this.nzMessageService.error(t, p)
    }

    lm(t: any, p?: any) {
        this.nzMessageService.loading(t, p)
    }
}
