<div [ngClass]="{
  'vb__layout__grayBackground': settings.isGrayBackground
}">
    <nz-layout [ngClass]="{
  'vb__layout': true,
  'vb__layout__contentMaxWidth': settings.isContentMaxWidth,
  'vb__layout__appMaxWidth': settings.isAppMaxWidth,
  'vb__layout__squaredBorders': settings.isSquaredBorders,
  'vb__layout__cardsShadow': settings.isCardShadow,
  'vb__layout__borderless': settings.isBorderless
}">
        <!--
        <vb-variants></vb-variants>
        -->
        <!-- classic left menu -->
        <nz-sider
                *ngIf="settings.layoutMenu === 'classic' && settings.menuLayoutType === 'left' && !settings.isMobileView"
                [nzWidth]="settings.isMenuCollapsed ? 80 : settings.leftMenuWidth" style="transition: none !important">
            <vb-menu-classic-left></vb-menu-classic-left>
        </nz-sider>
        <div *ngIf="settings.layoutMenu === 'classic' && settings.isMobileView">
            <div (click)="toggleMobileMenu()" class="handler">
                <div class="handlerIcon"></div>
            </div>
            <nz-drawer (nzOnClose)="toggleMobileMenu()" [nzClosable]="false" [nzVisible]="settings?.isMobileMenuOpen"
                       [nzWrapClassName]="'mobileMenu'"
                       nzPlacement="left">
                <ng-container *nzDrawerContent>
                    <vb-menu-classic-left></vb-menu-classic-left>
                </ng-container>
            </nz-drawer>
        </div>
        <nz-layout>
            <nz-header *ngIf="settings.layoutTopbar === 'v1'" [ngClass]="{
        'vb__layout__header': true,
        'vb__layout__fixedHeader': settings.isTopbarFixed,
        'vb__layout__headerGray': settings.isGrayTopbar,
        'vb__layout__separatedHeader': settings.isTopbarSeparated
      }">
                <vb-topbar></vb-topbar>
            </nz-header>
            <nz-content class="vb__layout__content">
                <div [@fadein]="routeAnimation(outlet, 'fadein')"
                     [@slideFadeinRight]="routeAnimation(outlet, 'slideFadeinRight')"
                     [@slideFadeinUp]="routeAnimation(outlet, 'slideFadeinUp')"
                     [@zoomFadein]="routeAnimation(outlet, 'zoomFadein')">
                    <router-outlet #outlet="outlet"></router-outlet>
                </div>
            </nz-content>
        </nz-layout>
    </nz-layout>
</div>
<div class="position-fixed" style="z-index: 2000;">
    <div class="position-fixed bottom-0 end-0 m-1">
        <div class="d-block d-sm-none">
            <button class="btn btn-danger btn-sm">XS</button>
        </div>
        <div class="d-none d-sm-block d-md-none">
            <button class="btn btn-warning btn-sm">SM</button>
        </div>
        <div class="d-none d-md-block d-lg-none">
            <button class="btn btn-primary btn-sm">MD</button>
        </div>
        <div class="d-none d-lg-block d-xl-none">
            <button class="btn btn-success btn-sm">LG</button>
        </div>
        <div class="d-none d-xl-block d-xxl-none">
            <button class="btn btn-info btn-sm">XL</button>
        </div>
        <div class="d-none d-xxl-block">
            <button class="btn btn-dark btn-sm">XXL</button>
        </div>
    </div>
</div>
