<a [nzDropdownMenu]="menu" [nzTrigger]="'click'" class="dropdown" nz-dropdown>
    <i class="icon bi bi-card-checklist"></i>
    <span class="d-none d-xl-inline">
    {{ 'topBar.projectManagement' | translate }}
  </span>
</a>
<nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
        <li nz-menu-group>
            <span title>Active</span>
            <ul class="list-unstyled">
                <li nz-menu-item>
                    <a href="javascript: void(0);">Project Management</a>
                </li>
                <li nz-menu-item>
                    <a href="javascript: void(0);">User Interface Development</a>
                </li>
                <li nz-menu-item>
                    <a href="javascript: void(0);">Documentation</a>
                </li>
            </ul>
        </li>
        <li nz-menu-group>
            <span title>Inactive</span>
            <ul class="list-unstyled">
                <li nz-menu-item>
                    <a href="javascript: void(0);">Marketing</a>
                </li>
            </ul>
        </li>
        <li nz-menu-divider></li>
        <li nz-menu-item>
            <a href="javascript: void(0);">
                <i class="bi bi-gear mr-2"></i>
                Settings
            </a>
        </li>
    </ul>
</nz-dropdown-menu>
