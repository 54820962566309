import {NgModule} from '@angular/core'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {RouterModule, Routes} from '@angular/router'
import {AppPreloader} from '@app/app-routing-loader'
import {SharedModule} from '@app/shared.module'
import {AuthGuard} from '@core/guards/auth.guard'

import {BuyerGuard} from '@core/guards/buyer.guard'
import {CustomerGuard} from '@core/guards/customer.guard'
import {SellerGuard} from '@core/guards/seller.guard'

import {LayoutAuthComponent} from '@layouts/auth/auth.component'
import {LayoutsModule} from '@layouts/layouts.module'
import {LayoutMainComponent} from '@layouts/main/main.component'
import {LayoutUserComponent} from '@layouts/user/user.component'

const routes: Routes = [
    {
        path: '',
        // redirectTo: 'user/organizations',
        redirectTo: 'auth/login',
        pathMatch: 'full'
    },
    {
        path: 'user',
        component: LayoutUserComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('src/app/modules/user/user.module').then(m => m.UserModule)
            }
        ]
    },
    {
        path: 'buyer',
        component: LayoutMainComponent,
        canActivate: [AuthGuard, BuyerGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('src/app/modules/buyer/buyer.module').then(m => m.BuyerModule)
            }
        ]
    },
    {
        path: 'seller',
        component: LayoutMainComponent,
        canActivate: [AuthGuard, SellerGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('src/app/modules/seller/seller.module').then(m => m.SellerModule)
            }
        ]
    },
    {
        path: 'customer',
        component: LayoutMainComponent,
        canActivate: [AuthGuard, CustomerGuard],
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('src/app/modules/customer/customer.module').then(m => m.CustomerModule)
            }
        ]
    },
    {
        path: 'auth',
        component: LayoutAuthComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('src/app/modules/auth/auth.module').then(m => m.AuthModule)
            }
        ]
    },
    {
        path: '**',
        redirectTo: '/auth/404'
    }
]

@NgModule({
    imports: [
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot(routes, {
            useHash: false,
            // initialNavigation: false,
            scrollPositionRestoration: 'top',
            preloadingStrategy: AppPreloader
        }),
        LayoutsModule
    ],
    declarations: [],
    providers: [AppPreloader],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
