export default {
    'topBar.issuesHistory': 'Issues',
    'topBar.projectManagement': 'Projects',
    'topBar.typeToSearch': 'Search...',
    'topBar.findPages': 'Find pages...',
    'topBar.actions': 'Actions',
    'topBar.status': 'Status',
    'topBar.profileMenu.hello': 'Merhaba',
    'topBar.profileMenu.billingPlan': 'Billing Plan',
    'topBar.profileMenu.role': 'Role',
    'topBar.profileMenu.email': 'Email',
    'topBar.profileMenu.phone': 'Phone',
    'topBar.profileMenu.editProfile': 'Edit Profile',
    'topBar.profileMenu.logout': 'Çıkış',
};
