import {NgModule} from '@angular/core'
import {CounterDirective} from './counter.directive'
import {PhoneMaskDirective} from './phoneMask.directive'

const DIRECTIVES = [PhoneMaskDirective, CounterDirective]

@NgModule({
    imports: [],
    declarations: [...DIRECTIVES],
    exports: [...DIRECTIVES]
})
export class DirectivesModule {
}
