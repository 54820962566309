import {Injectable, OnDestroy} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router'
import {AuthService} from '@auth/services/auth'
import {AuthStore} from '@core/store/auth.store'
import {AuthInterface} from '@interfaces'
import {Observable, Subscription} from 'rxjs'

@Injectable({
    providedIn: 'root'
})
export class CustomerGuard implements CanActivate, OnDestroy {
    private auth: AuthInterface
    private authStoreSubscription: Subscription

    constructor(
        private router: Router,
        private authStore: AuthStore,
        private authService: AuthService
    ) {
        this.auth = this.authStore.get()
        this.authStoreSubscription = this.authStore.authUpdate$.subscribe(auth => {
            console.log('CustomerGuard.authStore.subscribe', auth)
            this.auth = auth
        })
    }

    ngOnDestroy() {
        console.log('CustomerGuard.ngOnDestroy')
        this.auth = null
        this.authStoreSubscription.unsubscribe()
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.auth.type === 'customer') {
            return true
        }

        this.router
            //.navigate(['auth/login'], {queryParams: {returnUrl: state.url, guard: 'auth'}})
            // .navigate([this.authService.routes.login()])
            .navigate([this.authService.routes.login()], {queryParams: {guard: 'customer'}})
            .then()
        return false
    }
}
