import {DatePipe} from '@angular/common'
import {Pipe, PipeTransform} from '@angular/core'
import {dateFormats} from '@core/settings/dateFormats'

@Pipe({
    name: 'cDate'
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
    transform(value: any, format?: any): any {
        value = this.localTransform(value)
        return super.transform(value, dateFormats[format] ?? dateFormats['default'])
    }

    // mükerrer kod CustomDatePipe DateService
    localTransform(value: any) {
        if (value) {
            // is Date
            if (value instanceof Date) {
                return value
            }

            // 2020-01-02T01:23:45
            if (value.includes('T')) {
                return value
            }

            // 2020-01-02 01:23:45
            if (value.includes(' ')) {
                return value.replace(' ', 'T')
            }
        }

        // default
        return value
    }
}
