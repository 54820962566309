import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {Response} from '@core/services/response'

const routes = {
    unReads: () => '/user/notifications/un-reads',
    markAllRead: () => '/user/notifications/mark-all-read'
}

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    routes = routes

    constructor(private http: HttpClient) {
        //
    }

    unReads() {
        return this.http.get<Response>(routes.unReads())
    }

    markAllRead() {
        return this.http.put<Response>(routes.markAllRead(), {})
    }
}
