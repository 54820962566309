import {Injectable} from '@angular/core'
import {DateService} from '@core/services/date'
import * as _ from 'lodash'

@Injectable({
    providedIn: 'root'
})
export class FilterService {
    constructor(private dts: DateService) {
        //
    }

    replaceUrl(params?: any) {
        if (params?.replaceUrl === true || params?.replaceUrl === false) {
            return params.replaceUrl
        } else {
            return true
        }
    }

    sortable(sort: string, direction: string) {
        if (!(sort === null || direction === undefined)) {
            return {
                sort: sort,
                direction:
                    direction === 'ascend' || direction === 'descend' ? direction.replace('end', '') : ''
            }
        } else {
            return
        }
    }

    q(form?: any) {
        const listFilterParams = {}
        // TODO lodash each
        for (const key in form.value) {
            const keyValue = form.value[key]
            if (!(keyValue == null || keyValue === 'NaN')) {
                switch (key) {
                    case 'q':
                        if (!(keyValue === '')) {
                            _.set(listFilterParams, 'q', keyValue)
                            _.set(listFilterParams, 'qr', form.value.qr)
                            if (form.value.qc != null) {
                                _.set(listFilterParams, 'qc', form.value.qc)
                            }
                        }
                        break
                    case 'qr':
                    case 'qc':
                        break
                    default:
                        if (keyValue instanceof Date && keyValue.getTime()) {
                            _.set(listFilterParams, key, this.dts.format(keyValue, 'd'))
                        } else if (typeof keyValue === 'boolean') {
                            _.set(listFilterParams, key, keyValue === true ? 1 : 0)
                        } else {
                            _.set(listFilterParams, key, keyValue)
                        }
                        break
                }
            }
        }
        return listFilterParams
    }
}
