export const dateFormats = {
    r: 'yyyyMMdd',
    d: 'yyyy-MM-dd',
    t: 'HH:mm:ss',
    h: 'HH:mm',
    f: 'd LLLL yyyy EEEE H:mm',
    k: 'd LLLL yyyy',
    dm: 'd LLLL',
    my: 'LLLL yyyy',
    day: 'd',
    default: 'yyyy-MM-ddTHH:mm:ss'
}
