import {Injectable} from '@angular/core'
import {AuthOrganizationInterface} from '@interfaces'
import * as _ from 'lodash'
import {Subject} from 'rxjs'

@Injectable()
export class OrganizationStore {
    organization: AuthOrganizationInterface | null = {
        key: '',
        name: '',
        type: ''
    }

    private organizationUpdate = new Subject<AuthOrganizationInterface>()

    organizationUpdate$ = this.organizationUpdate.asObservable()

    update(organization: AuthOrganizationInterface) {
        this.organization = organization
        this.organizationUpdate.next(organization)
    }

    get = (): AuthOrganizationInterface => this.organization

    set(key: string, value: any) {
        _.set(this.organization, key, value)
        this.update(this.organization)
    }

    destroy() {
        this.update({
            key: '',
            name: '',
            type: ''
        })
    }
}
