// const moment = require('moment')
import { EnvironmentInterface } from '@environment/environment.interface'

export const environment: EnvironmentInterface = {
    // version: moment().format('YYMMDD'),
    version: '0.0.1',
    production: false,
    stage: 'local',
    apiUrl: 'https://ikamearacapi.turassist.com/api',
    googleMap: {
        apiKey: 'AIzaSyASQjZ38UuQujtRi4l-PYPqCuqIDNOFXD4',
        zoom: 10
    },
    app: {
        notification: {
            repeatTimeout: 2 // bildirimler icin yenileme suresi (dk)
        },
        upload: {
            images: ['image/jpg', 'image/jpeg', 'image/png']
        },
        bid: {
            maxVehicleAge: 4, // ihale eklerken en yuksek arac yasi
            locationDistance: 10, // lokasyon yapi capi
            quickDeadlineAddMinute: 30, // ikame baslangic tarihi ile ihale acilan gun ayni ise n dk ekler
            normalDeadlineAddMinute: 60 * 2 * 10 // ikame baslangic tarihi ihale acikan gunden sonra ise n dk ekler
        }
    }
}
