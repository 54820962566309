import {Component, OnDestroy, OnInit} from '@angular/core'
import {Router} from '@angular/router'
import {Notify} from '@core/services/notify'
import {environment} from '@environment/environment'
import {EnvironmentInterface} from '@environment/environment.interface'
import {NotificationInterface} from '@user/models/notifications'
import {NotificationsService} from '@user/services/notifications.service'
import {interval} from 'rxjs'
import {repeat} from 'rxjs/operators'
import {SubSink} from 'subsink'

@Component({
    selector: 'vb-topbar-actions',
    templateUrl: './actions.component.html',
    styleUrls: ['./actions.component.scss']
})
export class TopbarActionsComponent implements OnInit, OnDestroy {
    environment: EnvironmentInterface
    unreadNotificationCount: number = null
    unreadNotifications: Array<NotificationInterface> = []
    private subs = new SubSink()

    constructor(
        private router: Router,
        private notificationsService: NotificationsService,
        private nfy: Notify
    ) {
        //
    }

    async ngOnInit() {
        await this.getUnreadNotifications()
        this.subs.add(
            interval(1000 * 60 * environment.app.notification.repeatTimeout)
                .pipe(repeat())
                .subscribe(async () => {
                    await this.getUnreadNotifications()
                })
        )
    }

    ngOnDestroy() {
        this.subs.unsubscribe()
    }

    async getUnreadNotifications() {
        this.notificationsService.unReads().subscribe(res => {
            this.unreadNotifications = res.data
            this.unreadNotificationCount = res.meta.total
        })
    }

    async markAllRead() {
        this.notificationsService.markAllRead().subscribe(
            () => {
                this.unreadNotifications = []
                this.unreadNotificationCount = 0
            },
            err => {
                this.nfy.e(err.error.messages[0])
            }
        )
    }

    goNotification(notification: NotificationInterface) {
        if (notification.payload.params.path) {
            this.router.navigate([notification.payload.params.path]).then()
        }
    }
}
