import {Pipe, PipeTransform} from '@angular/core'
import * as _ from 'lodash'

@Pipe({
    name: 'tdClass'
})
export class TdClassPipe implements PipeTransform {
    transform(column: string, columns: any) {
        const columnData = _.find(columns, {column: column})
        const classes = []
        if (columnData?.mobileHide === true) {
            classes.push('mobile-hide')
        }
        if (columnData?.mobileShow === true) {
            classes.push('mobile-show')
        }
        if (columnData?.class) {
            classes.push(columnData.class)
        }
        return classes
    }
}
