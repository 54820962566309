import {CommonModule} from '@angular/common'
import {NgModule} from '@angular/core'
import {RouterModule} from '@angular/router'

import {DirectivesModule} from '@core/directive'
import {TranslateModule} from '@ngx-translate/core'

// antd components module-
import {AntdModule} from 'src/app/antd.module'

const MODULES = [
    CommonModule,
    RouterModule,
    AntdModule,
    TranslateModule,
    DirectivesModule
]

@NgModule({
    imports: [...MODULES],
    exports: [...MODULES]
})
export class SharedModule {
}
