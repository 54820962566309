import {NgModule} from '@angular/core'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'

import {CommonPipe} from '@app/common-pipe'
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar'
import {SharedModule} from 'src/app/shared.module'
import {MenuClassicLeftComponent} from './MenuClassic/MenuLeft/menu-left.component'
import {TopbarActionsComponent} from './Topbar/Actions/actions.component'
import {TopbarFavPagesComponent} from './Topbar/FavPages/fav-pages.component'
import {TopbarIssuesHistoryComponent} from './Topbar/IssuesHistory/issues-history.component'
import {TopbarLanguageSwitcherComponent} from './Topbar/LanguageSwitcher/language-switcher.component'
import {TopbarProjectManagementComponent} from './Topbar/ProjectManagement/project-management.component'
import {TopbarSearchComponent} from './Topbar/Search/search.component'

import {TopbarComponent} from './Topbar/topbar.component'
import {TopbarUserMenuComponent} from './Topbar/UserMenu/user-menu.component'

const COMPONENTS = [
    TopbarComponent,
    TopbarIssuesHistoryComponent,
    TopbarSearchComponent,
    TopbarUserMenuComponent,
    TopbarProjectManagementComponent,
    TopbarActionsComponent,
    TopbarLanguageSwitcherComponent,
    TopbarFavPagesComponent,
    MenuClassicLeftComponent
]

@NgModule({
    imports: [
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        CommonPipe
    ],
    declarations: [
        ...COMPONENTS
    ],
    exports: [
        ...COMPONENTS
    ]
})
export class ComponentsModule {
}
