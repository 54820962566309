import {CommonModule} from '@angular/common'
import {NgModule} from '@angular/core'
import {CustomDatePipe} from '@core/pipe/custom-date.pipe'
import {DateBadgePipe} from '@core/pipe/date-badge.pipe'

import {DgtPipe} from '@core/pipe/dgt.pipe'
import {DurationPipe} from '@core/pipe/duration.pipe'
import {MnyPipe} from '@core/pipe/mny.pipe'
import {NmbrPipe} from '@core/pipe/nmbr.pipe'
import {RandomPipe} from '@core/pipe/random.pipe'
import {SafePipe} from '@core/pipe/safe.pipe'
import {TdClassPipe} from '@core/pipe/td-class.pipe'
import {TimeAgoPipe} from '@core/pipe/time-ago.pipe'

const PIPES = [
    MnyPipe,
    DgtPipe,
    NmbrPipe,
    RandomPipe,
    TimeAgoPipe,
    DateBadgePipe,
    CustomDatePipe,
    DurationPipe,
    SafePipe,
    TdClassPipe
]

@NgModule({
    declarations: [...PIPES],
    imports: [CommonModule],
    exports: [...PIPES]
})
export class CommonPipe {
}
