import {Injectable} from '@angular/core'
import {menuAdmin} from '@core/services/menu/admin.menu'
import {menuBuyer} from '@core/services/menu/buyer.menu'
import {menuCustomer} from '@core/services/menu/customer.menu'
import {MenuInterface} from '@core/services/menu/menu.interface'
import {AuthStore} from '@core/store/auth.store'
import {OrganizationStore} from '@core/store/organization.store'
import {AuthInterface, AuthOrganizationInterface} from '@interfaces'
import {Observable, of} from 'rxjs'
import {menuSeller} from 'src/app/core/services/menu/seller.menu'


@Injectable({
    providedIn: 'root'
})
export class MenuService {

    private auth: AuthInterface
    private organization: AuthOrganizationInterface
    // private authStoreSubscription: Subscription
    // private organizationStoreSubscription: Subscription

    constructor(
        private organizationStore: OrganizationStore,
        private authStore: AuthStore
    ) {
        this.auth = this.authStore.get()
        this.organization = this.organizationStore.get()

        // this.authStoreSubscription = this.authStore.authUpdate$.subscribe(auth => {
        //     this.auth = auth
        // })
        //
        // this.organizationStoreSubscription = this.organizationStore.organizationUpdate$.subscribe(organization => {
        //     this.organization = organization
        // })
    }

    getMenuData(): Observable<any[]> {
        let menu: Array<MenuInterface> = []
        switch (this.auth.type) {
            case 'customer':
                menu = menuCustomer
                break
            case 'admin':
                menu = menuAdmin
                break
            case 'user':
                switch (this.organization.type) {
                    case 'buyer':
                        menu = menuBuyer
                        break
                    case 'seller':
                        menu = menuSeller
                        break
                }
                break
        }
        return of(menu)
    }
}
