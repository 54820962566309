import {formatNumber} from '@angular/common'
import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
    name: 'nmbr'
})
export class NmbrPipe implements PipeTransform {
    // {{ nmbr | nmbr: { locale: 'tr', dec: 2 } }}
    transform(value: number, values?: any): string {
        let dec = 2
        let locale = 'tr'
        if (values !== undefined) {
            if (values.dec !== undefined) {
                dec = values.dec
            }

            if (values.locale !== undefined) {
                locale = values.locale
            }
        }
        const digitsInfo = '.' + dec + '-' + dec
        return formatNumber(value, locale, digitsInfo)
    }
}
