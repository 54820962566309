<nz-sider (nzCollapsedChange)="onCollapse($event)" [(nzCollapsed)]="isMenuCollapsed && !isMobileView" [ngClass]="{
  menu: true,
  white: menuColor === 'white',
  gray: menuColor === 'gray',
  dark: menuColor === 'dark',
  unfixed: isMenuUnfixed,
  shadow: isMenuShadow && !isMobileView
}"
          [nzCollapsible]="!isMobileView" [nzWidth]="leftMenuWidth" nzCollapsible>
    <div [ngStyle]="{
    width: isMenuCollapsed && !isMobileView ? '80px' : leftMenuWidth + 'px',
    height: isMobileView || isMenuUnfixed ? 'calc(100% - 64px)' : 'calc(100% - 110px)'
  }" class="menuOuter">
        <div [routerLink]="['/']" class="logoContainer cur-p">
            <div class="logo">
                <svg height="24px" version="1.1" viewBox="0 0 24 24" width="24px" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path
                                d="M12,10.9c-0.1,0-0.2,0-0.2-0.1L3.5,6.1C3.4,6,3.3,5.8,3.3,5.6c0-0.2,0.1-0.3,0.2-0.4l8.2-4.7c0.2-0.1,0.3-0.1,0.5,0      l8.2,4.7c0.2,0.1,0.2,0.3,0.2,0.4S20.6,6,20.5,6.1l-8.2,4.7C12.2,10.8,12.1,10.9,12,10.9z M4.8,5.6L12,9.8l7.2-4.2L12,1.5      L4.8,5.6z"
                                fill="#4b7cf3" stroke="#4b7cf3"
                                strokeWidth="1"/>
                        <path
                                d="M13.6,23.6c-0.1,0-0.2,0-0.2-0.1c-0.2-0.1-0.2-0.3-0.2-0.4v-9.5c0-0.2,0.1-0.3,0.2-0.4l8.2-4.7c0.2-0.1,0.3-0.1,0.5,0      c0.2,0.1,0.2,0.3,0.2,0.4v9.5c0,0.2-0.1,0.3-0.3,0.4l-8.2,4.7C13.8,23.6,13.7,23.6,13.6,23.6z M14.1,13.9v8.3l7.2-4.2V9.8      L14.1,13.9z"
                                fill="#4b7cf3" stroke="#4b7cf3"
                                strokeWidth="1"/>
                        <path
                                d="M10.4,23.6c-0.1,0-0.2,0-0.2-0.1l-8.2-4.7c-0.2-0.1-0.3-0.3-0.3-0.4V8.9c0-0.2,0.1-0.3,0.2-0.4c0.2-0.1,0.3-0.1,0.5,0      l8.2,4.7c0.2,0.1,0.2,0.3,0.2,0.4v9.5c0,0.2-0.1,0.3-0.2,0.4C10.5,23.6,10.5,23.6,10.4,23.6z M2.7,18.1l7.2,4.2v-8.3L2.7,9.8      V18.1z"
                                fill="#4b7cf3" stroke="#4b7cf3"
                                strokeWidth="1"/>
                    </g>
                </svg>
                <div class="name">{{ organizationName }}</div>
            </div>
        </div>
        <perfect-scrollbar>
            <ul [nzInlineCollapsed]="isMenuCollapsed && !isMobileView" [nzInlineIndent]="15" [nzMode]="'inline'"
                class="navigation"
                nz-menu>
                <ng-container
                        *ngTemplateOutlet="menuTpl; context: {$implicit: menuDataActivated, level: 0}"></ng-container>
                <ng-template #menuTpl let-level="level" let-menuDataActivated>
                    <ng-container *ngFor="let item of menuDataActivated">
                        <ng-container>
                            <ng-template #tooltipTpl>
                                {{ item.title }}
                            </ng-template>
                            <!-- group title item -->
                            <li *ngIf="item.category" [nzTitle]="item.title" nz-menu-group></li>
                            <!-- disabled item -->
                            <li *ngIf="item.disabled" [nzDisabled]="true" [nzSelected]="item.selected"
                                [nzTooltipTitle]="isMenuCollapsed && level === 0 ? tooltipTpl : ''" nz-menu-item
                                nz-tooltip nzTooltipPlacement="right">
                <span [ngStyle]="{'padding-left.px': isMenuCollapsed ? '0' : level * 15}">
                  <span class="title">{{item.title}}</span>
                  <span *ngIf="item.count" class="badge badge-success ml-2">{{ item.count }}</span>
                  <i *ngIf="item.icon" [ngClass]="[item.icon, 'icon']"></i>
                </span>
                            </li>
                            <!-- main item -->
                            <li *ngIf="!item.children && item.url" [nzDisabled]="item.disabled"
                                [nzSelected]="item.selected"
                                [nzTooltipTitle]="isMenuCollapsed && level === 0 ? tooltipTpl : ''"
                                [queryParams]="item?.queryParams" [routerLink]="item.url" nz-menu-item
                                nz-tooltip nzTooltipPlacement="right">
                <span [ngStyle]="{'padding-left.px': isMenuCollapsed ? '0' : level * 15}">
                  <span class="title">{{item.title}}</span>
                  <span *ngIf="item.count" class="badge badge-success ml-2">{{ item.count }}</span>
                  <i *ngIf="item.icon" [ngClass]="[item.icon, 'icon']"></i>
                </span>
                            </li>
                            <!-- submenu item with recursive template -->
                            <li *ngIf="item.children" [nzOpen]="item.open && !isMenuCollapsed" nz-submenu>
                <span [ngStyle]="{'padding-left.px': isMenuCollapsed ? '0' : level * 15}" title>
                  <span class="title">{{item.title}}</span>
                  <span *ngIf="item.count" class="badge badge-success ml-2">{{ item.count }}</span>
                  <i *ngIf="item.icon" [ngClass]="[item.icon, 'icon']"></i>
                </span>
                                <ul>
                                    <ng-container
                                            *ngTemplateOutlet="menuTpl; context: {$implicit: item.children, level: level + 1}">
                                    </ng-container>
                                </ul>
                            </li>
                        </ng-container>
                    </ng-container>
                </ng-template>
            </ul>
        </perfect-scrollbar>
    </div>
</nz-sider>
