import {Injectable, OnDestroy} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router'
import {AuthService} from '@auth/services/auth'
import {AuthStore} from '@core/store/auth.store'
import {Observable, Subscription} from 'rxjs'

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, OnDestroy {
    //public auth
    private authorized: boolean = false
    private authStoreSubscription: Subscription

    constructor(
        private router: Router,
        private authStore: AuthStore,
        private authService: AuthService
    ) {
        console.log('AuthGuard.constructor')

        this.authStoreSubscription = this.authStore.authUpdate$.subscribe(auth => {
            console.log('AuthGuard.authStore.subscribe', auth)
            this.authorized = auth.authorized
        })

        // console.log('AuthGuard.constructor')
        // // this.auth = this.authStore.get()
        // this.authorized = this.authStore.get().authorized
        // console.log('AuthGuard.constructor.authorized', this.authorized)

        // this.subs.add(
        //     this.authStore.authUpdate$.subscribe((auth: AuthInterface) => {
        //         console.log('AuthGuard.userStore.subscribe', auth)
        //         // this.auth = auth
        //         this.authorized = user.authorized
        //
        //     })
        // )
    }

    ngOnDestroy() {
        this.authorized = false
        this.authStoreSubscription.unsubscribe()
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authorized === true) {
            return true
        }

        this.router
            //.navigate(['auth/login'], {queryParams: {returnUrl: state.url, guard: 'auth'}})
            //.navigate([this.authService.routes.login()])
            .navigate([this.authService.routes.login()], {queryParams: {guard: 'auth'}})
            .then()
        return false
    }
}
