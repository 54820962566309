import {Injectable, OnDestroy} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router'
import {AuthService} from '@auth/services/auth'
import {OrganizationStore} from '@core/store/organization.store'
import {AuthOrganizationInterface} from '@interfaces'
import {WebStorageService} from 'ngx-web-storage'
import {Observable, Subscription} from 'rxjs'

@Injectable({
    providedIn: 'root'
})
export class BuyerGuard implements CanActivate, OnDestroy {
    private organizationKey: string = 'buyer'
    private organization: AuthOrganizationInterface
    private organizationStoreSubscription: Subscription

    constructor(
        private router: Router,
        private storage: WebStorageService,
        private organizationStore: OrganizationStore,
        private authService: AuthService
    ) {
        this.organization = this.organizationStore.organization

        console.log('BuyerGuard.constructor')

        this.organizationStoreSubscription = this.organizationStore.organizationUpdate$.subscribe(organization => {
            console.log('BuyerGuard.organizationStore.subscribe', organization)
            this.organization = organization
        })
    }

    ngOnDestroy() {
        console.log('BuyerGuard.ngOnDestroy')
        this.organization = null
        this.organizationStoreSubscription.unsubscribe()
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.organization.type === this.organizationKey) {
            return true
        }

        this.storage.session.remove('organizationKey')
        this.organizationStore.destroy()

        this.router
            // .navigate(['/user/organizations'], {queryParams: {returnUrl: state.url}})
            .navigate([this.authService.routes.organizations()])
            .then()
        return false
    }
}
