import {NgModule} from '@angular/core'
import {ComponentsModule} from '@theme/components/components.module'
import {SharedModule} from '../shared.module'

import {LayoutAuthComponent} from './auth/auth.component'
import {LayoutMainComponent} from './main/main.component'
import {LayoutPublicComponent} from './public/public.component'
import {LayoutUserComponent} from './user/user.component'

const COMPONENTS = [
    LayoutAuthComponent,
    LayoutMainComponent,
    LayoutUserComponent,
    LayoutPublicComponent
]

@NgModule({
    imports: [SharedModule, ComponentsModule],
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS]
})
export class LayoutsModule {
}
