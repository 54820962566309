import {Injectable} from '@angular/core'
import {Title} from '@angular/platform-browser'
import {SettingStore} from '@core/store/setting.store'
import * as _ from 'lodash'

@Injectable({
    providedIn: 'root'
})
export class CustomService {
    constructor(private titleService: Title, private settingStore: SettingStore) {
        //
    }

    browserTitle(params?: any) {
        this.titleService.setTitle(_.join(params, ' | '))
    }

    pageTitle(title: string, params?: any) {
        this.settingStore.set('pageTitle', title)
    }

    drawerWidth(params?: any) {
        // [nzWidth]="_cs.drawerWidth()"
        if (params?.width) {
            return params.width
        } else {
            if (params?.desktopWidth) {
                return params.desktopWidth
            }
            return 400
        }
    }

    descriptionLayout() {
        return this.breakPoints() === 'xs' ? 'vertical' : 'horizontal'
    }

    breakPoints() {
        const width = window.innerWidth
        if (width < 576) {
            return 'xs'
        } else if (width < 768) {
            return 'sm'
        } else if (width < 992) {
            return 'md'
        } else if (width < 1200) {
            return 'lg'
        } else {
            return 'xl'
        }
    }
}
