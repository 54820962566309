import {Component} from '@angular/core'

@Component({
    selector: 'layout-public',
    template: `
        <router-outlet></router-outlet>
    `
})
export class LayoutPublicComponent {
}
