import {Component, OnDestroy, OnInit} from '@angular/core'
import {RouterOutlet} from '@angular/router'
import {SettingStore} from '@core/store/setting.store'
import {SettingInterface} from '@interfaces'
import {SubSink} from 'subsink'
import {fadein, slideFadeinRight, slideFadeinUp, zoomFadein} from '../router-animations'

@Component({
    selector: 'layout-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
    animations: [slideFadeinUp, slideFadeinRight, zoomFadein, fadein]
})
export class LayoutMainComponent implements OnInit, OnDestroy {
    touchStartPrev: Number = 0
    touchStartLocked: Boolean = false
    settings: SettingInterface
    private subs = new SubSink()

    constructor(private settingStore: SettingStore) {
        this.settings = this.settingStore.get()
        this.subs.add(
            this.settingStore.settingUpdate$.subscribe((settings: SettingInterface) => {
                this.settings = settings
            })
        )
    }

    ngOnDestroy() {
        this.subs.unsubscribe()
    }

    ngOnInit() {
        this.bindMobileSlide()
    }

    onCollapse(value: boolean) {
        this.settingStore.set('isMenuCollapsed', value)
    }

    toggleCollapsed() {
        this.settingStore.set('isMenuCollapsed', !this.settings.isMenuCollapsed)
    }

    toggleMobileMenu() {
        this.settingStore.set('isMobileMenuOpen', !this.settings.isMobileMenuOpen)
    }

    bindMobileSlide() {
        // mobile menu touch slide opener
        const unify = e => {
            return e.changedTouches ? e.changedTouches[0] : e
        }
        document.addEventListener(
            'touchstart',
            e => {
                const x = unify(e).clientX
                this.touchStartPrev = x
                this.touchStartLocked = x > 70 ? true : false
            },
            {passive: false}
        )
        document.addEventListener(
            'touchmove',
            e => {
                const x = unify(e).clientX
                const prev = this.touchStartPrev
                if (x - <any>prev > 50 && !this.touchStartLocked) {
                    this.toggleMobileMenu()
                    this.touchStartLocked = true
                }
            },
            {passive: false}
        )
    }

    // @ts-ignore
    routeAnimation(outlet: RouterOutlet, animation: string) {
        const mapAnimation = {
            'slide-fadein-up': 'slideFadeinUp',
            'slide-fadein-right': 'slideFadeinRight',
            fadein: 'zoomFadein',
            'zoom-fadein': 'fadein'
        }
        if (animation === mapAnimation[this.settings.routerAnimation]) {
            return outlet.isActivated && outlet.activatedRoute.routeConfig.path
        }
    }
}
