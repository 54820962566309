import {MenuInterface} from '@core/services/menu/menu.interface'

export const menuSeller: Array<MenuInterface> = [
    {
        title: 'Dashboard-seller',
        key: '__seller_dashboard',
        icon: 'bi bi-house',
        children: [
            {
                title: 'Dashboard-seller',
                key: '__seller_dashboard_main',
                url: '/seller/dashboard/main'
            },
            {
                title: 'Takvim',
                key: '__seller_dashboard_calendar',
                url: '/seller/dashboard/calendar'
            }
        ]
    },
    {
        title: 'İhale',
        key: '__sellers_bids',
        icon: 'bi bi-list',
        //   // all|hasOffer|noOffer|won|lose
        children: [
            {
                title: 'Devam Eden İhaleler',
                key: '__seller_bids_list_stage_offer_active',
                url: '/seller/bids',
                queryParams: {stage: 'offer_active'}
            },
            {
                title: 'Teklif Verdiğim İhaleler',
                key: '__seller_bids_list_hasOffer',
                url: '/seller/bids',
                queryParams: {status: 'hasOffer'}
            },
            {
                title: 'Teklif Vermediğim İhaleler',
                key: '__seller_bids_list_noOffer',
                url: '/seller/bids',
                queryParams: {status: 'noOffer'}
            },
            {
                title: 'Kazandığım İhaleler',
                key: '__seller_bids_list_win',
                url: '/seller/bids',
                queryParams: {status: 'win'}
            },
            {
                title: 'Kaybettiğim İhaleler',
                key: '__seller_bids_list_lose',
                url: '/seller/bids',
                queryParams: {status: 'lose'}
            },
            {
                title: 'Onay Bekleyen İhaleler',
                key: '__seller_bids_list_deliveryChangeRequestStage',
                url: '/seller/bids',
                queryParams: {deliveryChangeRequestStage: 'waiting'}
            },
            {
                title: 'Reddedilen İhaleler',
                key: '__seller_bids_list_deliveryChangeRequestStage_rejected',
                url: '/seller/bids',
                queryParams: {deliveryChangeRequestStage: 'rejected'}
            },
            {
                title: 'Tüm İhaleler',
                key: '__seller_bids_list_all',
                url: '/seller/bids'
                // queryParams: {'status': 'all'},
            }
        ]
    }
]
