import {Component, OnDestroy, OnInit} from '@angular/core'
import {NavigationStart, Router} from '@angular/router'
import {MenuService} from '@core/services/menu'
import {OrganizationStore} from '@core/store/organization.store'
import {SettingStore} from '@core/store/setting.store'
import {SettingInterface} from '@interfaces'
import * as _ from 'lodash'
import {filter} from 'rxjs/operators'
import {SubSink} from 'subsink'

@Component({
    selector: 'vb-menu-classic-left',
    templateUrl: './menu-left.component.html',
    styleUrls: ['./menu-left.component.scss']
})
export class MenuClassicLeftComponent implements OnInit, OnDestroy {
    menuColor: string
    isMenuShadow: boolean
    isMenuUnfixed: boolean
    isSidebarOpen: boolean
    isMobileView: boolean
    leftMenuWidth: number
    isMenuCollapsed: boolean
    title: string
    menuData: any[]
    menuDataActivated: any[]
    organizationName: string
    private subs = new SubSink()

    constructor(
        private menuService: MenuService,
        private settingStore: SettingStore,
        private router: Router,
        private organizationStore: OrganizationStore
    ) {
        this.menuService.getMenuData().subscribe(menuData => (this.menuData = menuData))
        this.organizationName = this.organizationStore.get().name
        this.subs.add(
            this.settingStore.settingUpdate$.subscribe((settings: SettingInterface) => {
                this.menuColor = settings.menuColor
                this.isMenuShadow = settings.isMenuShadow
                this.isMenuUnfixed = settings.isMenuUnfixed
                this.isSidebarOpen = settings.isSidebarOpen
                this.isMobileView = settings.isMobileView
                this.leftMenuWidth = settings.leftMenuWidth
                this.isMenuCollapsed = settings.isMenuCollapsed
                this.title = settings.title
            })
        )
    }

    ngOnDestroy() {
        this.subs.unsubscribe()
    }

    ngOnInit() {
        this.activateMenu(this.router.url)
        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                this.activateMenu(event.url ? event.url : null)
            })
    }

    activateMenu(url: any, menuData = this.menuData) {
        menuData = JSON.parse(JSON.stringify(menuData))
        const pathWithSelection = this.getPath({url: url}, menuData, (entry: any) => entry, 'url')
        if (pathWithSelection) {
            pathWithSelection.pop().selected = true
            _.forEach(pathWithSelection, (parent: any) => (parent.open = true))
        }
        this.menuDataActivated = menuData.slice()
    }

    getPath(
        element: any,
        source: any,
        property: any,
        keyProperty = 'key',
        childrenProperty = 'children',
        path = []
    ) {
        let found = false
        const getElementChildren = (value: any) => _.get(value, childrenProperty)
        const getElementKey = (value: any) => _.get(value, keyProperty)
        const key = getElementKey(element)
        return (
            _.some(source, (e: any) => {
                if (getElementKey(e) === key) {
                    path.push(e)
                    return true
                } else {
                    return (found = this.getPath(
                        element,
                        getElementChildren(e),
                        property,
                        keyProperty,
                        childrenProperty,
                        path.concat(e)
                    ))
                }
            }) &&
            (found || _.map(path, property))
        )
    }

    toggleSettings() {
        this.settingStore.set('isSidebarOpen', !this.isSidebarOpen)
    }

    onCollapse(value: any) {
        this.settingStore.set('isMenuCollapsed', value)
    }
}
