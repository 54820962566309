import {Pipe, PipeTransform} from '@angular/core'
import * as moment from 'moment'

@Pipe({
    name: 'dateBadge'
})
export class DateBadgePipe implements PipeTransform {
    transform(value: any): any {
        value = this.localTransform(value)
        if (value) {
            const diffDays = moment
                .duration(moment(value, 'YYYY-MM-DD').diff(moment().startOf('day')))
                .asDays()
            if (diffDays === 0) {
                return 'blue'
            }
            if (diffDays < 0) {
                return 'red'
            }
            if (diffDays < 7) {
                return 'orange'
            }
            if (diffDays < 14) {
                return 'yellow'
            }
            return 'green'
        } else {
            return null
        }
    }

    // mükerrer kod CustomDatePipe DateService
    localTransform(value: any) {
        if (value) {
            // is Date
            if (value instanceof Date) {
                return value
            }

            // 2020-01-02T01:23:45
            if (value.includes('T')) {
                return value
            }

            // 2020-01-02 01:23:45
            if (value.includes(' ')) {
                return value.replace(' ', 'T')
            }
        }

        // default
        return value
    }
}
