<div>
    <div [nzDropdownMenu]="menu" class="dropdown" nz-dropdown nzPlacement="bottomRight" nzTrigger="click">
        <div class="flag">
            <img [alt]="language" [src]="mapFlags[language]"/>
            <span class="text-uppercase">{{language}}</span>
        </div>
    </div>
    <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
            <li (click)="changeLanguage('tr-TR')" nz-menu-item>
        <span class="menuIcon">
          <img [src]="mapFlags.tr" alt="Türkçe"/>
        </span>
                Türkçe
            </li>
            <li (click)="changeLanguage('en-US')" nz-menu-item>
        <span class="menuIcon">
          <img [src]="mapFlags.en" alt="English"/>
        </span>
                English
            </li>
        </ul>
    </nz-dropdown-menu>
</div>
