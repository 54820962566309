import {Injectable} from '@angular/core'

export interface TreeNodeInterface {
    key: string
    level?: number
    expand?: boolean
    children?: TreeNodeInterface[]
    parent?: TreeNodeInterface
    isParent?: boolean
    name?: any
    data?: any
}

@Injectable()
export class TreeTable {
    mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {}

    treeTable(listOfMapData) {
        listOfMapData.forEach((val: any) => {
            this.mapOfExpandedData[val.key] = this.convertTreeToList(val)
        })

        return this.mapOfExpandedData
    }

    convertTreeToList(root: TreeNodeInterface): TreeNodeInterface[] {
        const stack: TreeNodeInterface[] = []
        const array: TreeNodeInterface[] = []
        const hashMap = {}
        stack.push({...root, level: 0, expand: true})

        while (stack.length !== 0) {
            const node = stack.pop()!
            this.visitNode(node, hashMap, array)
            if (node.children) {
                for (let i = node.children.length - 1; i >= 0; i--) {
                    stack.push({...node.children[i], level: node.level! + 1, expand: true, parent: node})
                }
            }
        }

        return array
    }

    visitNode(
        node: TreeNodeInterface,
        hashMap: { [key: string]: boolean },
        array: TreeNodeInterface[]
    ): void {
        if (!hashMap[node.key]) {
            hashMap[node.key] = true
            array.push(node)
        }
    }
}
