import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
    name: 'duration'
})
export class DurationPipe implements PipeTransform {
    transform(value: any): any {
        if (value == null) {
            return ''
        }
        const valueArray = value.split(':')
        const hour = parseInt(valueArray[0], 10)
        const minute = parseInt(valueArray[1], 10)
        let duration = ''
        if (hour > 0) {
            duration += hour + 's'
        }
        if (minute > 0) {
            duration += ' ' + minute + 'd'
        }
        return duration.trim()
    }
}
