<div>
    <div [nzDropdownMenu]="menu" class="dropdown" nz-dropdown nzTrigger="click">
        <nz-avatar [nzShape]="'square'" [nzSize]="'large'" class="avatar" nzIcon="user"></nz-avatar>
    </div>
    <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
            <li nz-menu-item>
                <strong>{{ 'topBar.profileMenu.hello' | translate }},<br/>{{auth?.name || 'Kullanıcı'}}</strong>
            </li>
            <li nz-menu-divider></li>
            <li nz-menu-item>
                <a [nzTooltipMouseEnterDelay]="3" [nzTooltipTitle]="environmentVersion"
                   [routerLink]="[authService.routes.logout()]"
                   nz-tooltip
                   nzTooltipPlacement="bottom"
                ><i class="bi bi-box-arrow-right mr-2"></i> {{ 'topBar.profileMenu.logout' | translate }}</a>
            </li>
        </ul>
    </nz-dropdown-menu>
</div>
