import {Component, OnDestroy} from '@angular/core'
import {SettingStore} from '@core/store/setting.store'
import {SettingInterface} from '@interfaces'
import {TranslateService} from '@ngx-translate/core'
import {SubSink} from 'subsink'

@Component({
    selector: 'vb-topbar-language-switcher',
    templateUrl: './language-switcher.component.html',
    styleUrls: ['./language-switcher.component.scss']
})
export class TopbarLanguageSwitcherComponent implements OnDestroy {
    language: string
    mapFlags = {
        tr: '/assets/flags/tr.svg',
        en: '/assets/flags/en.svg'
    }
    private subs = new SubSink()

    constructor(private translate: TranslateService, private settingStore: SettingStore) {
        this.subs.add(
            this.settingStore.settingUpdate$.subscribe((settings: SettingInterface) => {
                this.language = settings.locale.substr(0, 2)
            })
        )
    }

    ngOnDestroy() {
        this.subs.unsubscribe()
    }

    changeLanguage(locale: string) {
        this.settingStore.set('locale', locale)
    }
}
