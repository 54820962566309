import {MenuInterface} from '@core/services/menu/menu.interface'

export const menuAdmin: Array<MenuInterface> = [
    {
        title: 'Admin',
        key: '__dashboard',
        url: '/admin/dashboard',
        icon: 'bi bi-house'
    },
    {
        title: 'İhale',
        key: '__buyers_bids',
        icon: 'bi bi-list',
        children: [
            {
                title: 'İhaleler',
                key: '__buyers_bids_list',
                url: '/buyer/bids'
            },
            {
                title: 'İhale Ekle',
                key: '__buyers_bids_create',
                url: '/buyer/bids/create'
            }
        ]
    },

    {
        title: 'Müşteri',
        key: '__buyers_customers',
        icon: 'bi bi-people',
        children: [
            {
                title: 'Müşteriler',
                key: '__buyers_customers_list',
                url: '/buyer/customers'
            },
            {
                title: 'Müşteri Ekle',
                key: '__buyers_customers_create',
                url: '/buyer/customers/create'
            }
        ]
    }
]
