import {formatCurrency, getCurrencySymbol} from '@angular/common'
import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
    name: 'mny'
})
export class MnyPipe implements PipeTransform {
    // {{ mny | mny: { code:'TRY',locale: 'tr', dec: 2 } }}
    transform(value: number, values?: any): string {
        let dec = 2
        let locale = 'tr'
        let code = 'TRY'

        if (values !== undefined) {
            if (values.code !== undefined) {
                code = values.code
            }
            if (values.dec !== undefined) {
                dec = values.dec
            }
            if (values.locale !== undefined) {
                locale = values.locale
            }
        }
        const digitsInfo = '.' + dec + '-' + dec
        // money | currency:'TRY':'symbol-narrow':'':'tr'
        return formatCurrency(value, locale, getCurrencySymbol(code, 'narrow'), code, digitsInfo)
    }
}
