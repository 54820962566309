import {MenuInterface} from '@core/services/menu/menu.interface'

export const menuCustomer: Array<MenuInterface> = [
    {
        title: 'Dashboard-customer',
        key: '__customers_dashboard',
        icon: 'bi bi-house',
        url: '/seller/dashboard/main'
    },
    {
        title: 'İhaleler',
        key: '__customers_bids',
        icon: 'bi bi-list',
        url: '/customer/bids'
    },
    {
        title: 'İhaleler',
        key: '__customers_bids',
        icon: 'bi bi-list',
        children: [
            {
                title: 'Devam Eden İhaleler',
                key: '__customer_bids_list_stage_offer_active',
                url: '/seller/bids',
                queryParams: {stage: 'offer_active'}
            },
            {
                title: 'Tüm İhaleler',
                key: '__seller_bids_list_all',
                url: '/seller/bids'
            }
        ]
    }
]
