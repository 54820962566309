import {formatNumber} from '@angular/common'
import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
    name: 'dgt'
})
export class DgtPipe implements PipeTransform {
    transform(value: number): string {
        const dec = 0
        const locale = 'tr'
        const digitsInfo = '.' + dec + '-' + dec
        return formatNumber(value, locale, digitsInfo)
    }
}
