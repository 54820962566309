// locale resistration
import {APP_BASE_HREF, registerLocaleData} from '@angular/common'
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http'

import {default as localeTr} from '@angular/common/locales/tr'
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core'
import {FormsModule} from '@angular/forms'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {AuthService} from '@auth/services/auth'
import Bugsnag from '@bugsnag/js'
import {BugsnagErrorHandler} from '@bugsnag/plugin-angular'
import {CustomService} from '@core/services/custom'
import {DateService} from '@core/services/date'
import {FilterService} from '@core/services/filter'
import {ApiInterceptor} from '@core/services/interceptor'
import {Notify} from '@core/services/notify'
import {TreeTable} from '@core/services/treetable'
import {AuthStore} from '@core/store/auth.store'
import {OrganizationStore} from '@core/store/organization.store'
import {SettingStore} from '@core/store/setting.store'

import {NgProgressModule} from '@ngx-progressbar/core'
import {NgProgressHttpModule} from '@ngx-progressbar/http'
import {NgProgressRouterModule} from '@ngx-progressbar/router'
import {TranslateModule} from '@ngx-translate/core'
import {NZ_DATE_CONFIG, NZ_I18N, tr_TR as localeZorro} from 'ng-zorro-antd/i18n'
import {WebStorageModule} from 'ngx-web-storage'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'

const LOCALE_PROVIDERS = [
    {provide: LOCALE_ID, useValue: 'tr'},
    {provide: NZ_I18N, useValue: localeZorro},
    {
        provide: NZ_DATE_CONFIG,
        useValue: {
            firstDayOfWeek: 1
        }
    }
]

registerLocaleData(localeTr, 'tr')

Bugsnag.start({apiKey: 'c7d1558276a1583f0f85a2ebbac1e04f'})

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
    return new BugsnagErrorHandler()
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,

        // translate
        TranslateModule.forRoot(),

        WebStorageModule.forRoot({prefix: 'APP:'}),

        // nprogress
        NgProgressModule.withConfig({
            thick: true,
            spinner: false,
            color: '#0190fe'
        }),
        NgProgressRouterModule,
        NgProgressHttpModule
    ],
    providers: [
        // auth services
        AuthService,
        DateService,
        FilterService,
        CustomService,
        Notify,
        TreeTable,

        AuthStore,
        OrganizationStore,
        SettingStore,

        // fake http interceptors
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true
        },

        {provide: APP_BASE_HREF, useValue: '/'},
        {provide: ErrorHandler, useFactory: errorHandlerFactory},

        // locale providers
        ...LOCALE_PROVIDERS
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    //
}
