<div class="topbar p-0 d-grid">
    <div class="d-flex">
        <!--
        <vb-topbar-fav-pages class="mx-4"></vb-topbar-fav-pages>
        -->
        <vb-topbar-search class="me-auto"></vb-topbar-search>
        <!--
        <vb-topbar-issues-history></vb-topbar-issues-history>
        <vb-topbar-project-management class="ms-4"></vb-topbar-project-management>
        -->
        <vb-topbar-actions class="ms-4"></vb-topbar-actions>
        <vb-topbar-user-menu class="mx-4"></vb-topbar-user-menu>
    </div>
</div>
