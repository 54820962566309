export default {
    'topBar.issuesHistory': 'Issues',
    'topBar.projectManagement': 'Projects',
    'topBar.typeToSearch': 'Search...',
    'topBar.findPages': 'Find pages...',
    'topBar.actions': 'Actions',
    'topBar.status': 'Status',
    'topBar.profileMenu.hello': 'Hello',
    'topBar.profileMenu.billingPlan': 'Billing Plan',
    'topBar.profileMenu.role': 'Role',
    'topBar.profileMenu.email': 'Email',
    'topBar.profileMenu.phone': 'Phone',
    'topBar.profileMenu.editProfile': 'Edit Profile',
    'topBar.profileMenu.logout': 'Logout',

    'common.choose': 'choose',

    'buyer.bids.create.send': 'Send',
};
