import {Injectable} from '@angular/core'
import {AuthInterface} from '@interfaces'
import * as _ from 'lodash'
import {Subject} from 'rxjs'

@Injectable()
export class AuthStore {
    auth: AuthInterface = {
        authorized: false,
        name: '',
        type: '',
        accessToken: '',
        organizations: []
    }

    private authUpdate = new Subject<AuthInterface>()

    authUpdate$ = this.authUpdate.asObservable()

    update(auth: AuthInterface) {
        console.log('userStore.update', auth)
        this.auth = auth
        this.authUpdate.next(auth)
    }

    get = (): AuthInterface => this.auth

    set(key: string, value: any) {
        _.set(this.auth, key, value)
        this.update(this.auth)
    }

    destroy() {
        this.update({
            authorized: false,
            name: '',
            type: '',
            accessToken: '',
            organizations: []
        })
    }
}
