import {Injectable} from '@angular/core'
import {SettingInterface} from '@interfaces'
import * as _ from 'lodash'
import {Subject} from 'rxjs'

@Injectable()
export class SettingStore {
    setting: SettingInterface = {
        title: 'RS İkame Portalı',
        logo: 'logo.png',
        version: 'fluent',
        theme: 'default',
        locale: 'tr-TR',
        isSidebarOpen: false,
        isSupportChatOpen: false,
        isMobileView: false,
        isTabletView: false,
        isMobileMenuOpen: false,
        isMenuCollapsed: false,
        isPreselectedOpen: false,
        preselectedVariant: 'default',
        menuLayoutType: 'left',
        routerAnimation: 'slide-fadein-up',
        menuColor: 'gray',
        authPagesColor: 'white',
        isAuthTopbar: true,
        primaryColor: '#4b7cf3',
        leftMenuWidth: 256,
        isMenuUnfixed: false,
        isMenuShadow: false,
        isTopbarFixed: false,
        isTopbarSeparated: false,
        isGrayTopbar: false,
        isContentMaxWidth: false,
        isAppMaxWidth: false,
        isGrayBackground: false,
        isCardShadow: true,
        isSquaredBorders: false,
        isBorderless: false,
        layoutMenu: 'classic',
        layoutTopbar: 'v1',
        layoutBreadcrumbs: 'v1',
        layoutFooter: 'v1',
        flyoutMenuType: 'flyout',
        flyoutMenuColor: 'blue'
    }

    private settingUpdate = new Subject<SettingInterface>()

    settingUpdate$ = this.settingUpdate.asObservable()

    update(setting: SettingInterface) {
        this.setting = setting
        this.settingUpdate.next(setting)
    }

    get = (): SettingInterface => this.setting

    set(key: string, value: any) {
        _.set(this.setting, key, value)
        this.update(this.setting)
    }
}
