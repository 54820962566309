import {HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {Injectable, Injector} from '@angular/core'
import {Router} from '@angular/router'
import {environment} from '@environment/environment'
import {WebStorageService} from 'ngx-web-storage'
import {Observable, throwError} from 'rxjs'
import {catchError} from 'rxjs/operators'

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    apiUrl: string
    hostname: string

    constructor(
        private injector: Injector,
        private http: HttpClient,
        public router: Router,
        private storage: WebStorageService
    ) {
        this.apiUrl = environment.apiUrl
        this.hostname = window.location.hostname
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const reqUrlPaths = request.url.split('/')

        let accessToken: string = null
        let organizationKey: string = null
        switch (reqUrlPaths[0]) {
            case 'auth':
                /* token gerekmeyen durumlarda */
                break

            case 'user':
                accessToken = this.getAccessToken()

                if (reqUrlPaths[1] === 'select') {
                    organizationKey = this.getOrganizationKey()
                }

                break

            default:
                accessToken = this.getAccessToken()
                organizationKey = this.getOrganizationKey()
                break
        }

        if (accessToken) {
            request = request.clone({
                headers: request.headers.set('Authorization', 'Bearer ' + accessToken)
            })
        }

        if (organizationKey) {
            request = request.clone({
                headers: request.headers.set('X-OrganizationKey', organizationKey)
            })
        }

        request = request.clone({
            url: this.apiUrl + request.url
        })

        if (!environment.production) {
            console.log('REQ-URL', decodeURIComponent(request.urlWithParams))
        }

        // return next.handle(request)

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (!environment.production) {
                    console.log('error.status', error.status)
                }
                // if (error.status === 401) this.router.navigate(['/auth/login'])
                return throwError(error)
            })
        )
    }

    getAccessToken() {
        return this.storage.local.get('accessToken')
    }

    getOrganizationKey() {
        return this.storage.session.get('organizationKey')
    }
}
