import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
    name: 'random'
})
export class RandomPipe implements PipeTransform {
    transform(data) {
        const max = 9999
        const min = 1000
        return Math.floor(Math.random() * (max - min + 1)) + min
    }
}
