import {Component, OnDestroy} from '@angular/core'
import {AuthService} from '@auth/services/auth'
import {AuthStore} from '@core/store/auth.store'
import {AuthInterface} from '@interfaces'
import {environment} from 'src/environments/environment'
import {SubSink} from 'subsink'

@Component({
    selector: 'vb-topbar-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss']
})
export class TopbarUserMenuComponent implements OnDestroy {
    environmentVersion: string
    auth: AuthInterface
    private subs = new SubSink()

    constructor(
        public authService: AuthService,
        private authStore: AuthStore
    ) {
        this.environmentVersion = environment.version + '' + (environment.stage ? '-' + environment.stage : '')

        this.auth = this.authStore.get()

        this.subs.add(
            this.authStore.authUpdate$.subscribe((auth: AuthInterface) => {
                this.auth = auth
            })
        )
    }

    ngOnDestroy() {
        this.subs.unsubscribe()
    }
}
