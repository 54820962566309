<div>
    <div [nzDropdownMenu]="menu" class="dropdown" nz-dropdown nzTrigger="click">
        <nz-badge [nzCount]="unreadNotificationCount">
            <i class="icon bi bi-bell"></i>
        </nz-badge>
    </div>
    <nz-dropdown-menu #menu="nzDropdownMenu">
        <div class="card vb__utils__shadow width-350">
            <div class="card-body p-0">
                <div>
                    <nz-tabset [nzSelectedIndex]="0" class="tabs vb-tabs-bordered">
                        <nz-tab nzTitle="Bildirimler">
                            <ng-container *ngIf="unreadNotifications.length; else contentNoUnread">
                                <perfect-scrollbar style="height: 350px">
                                    <div class="text-gray-6">
                                        <ul class="list-unstyled">
                                            <ng-container *ngFor="let notification of unreadNotifications">
                                                <li (click)="goNotification(notification)"
                                                    [nzPopoverContent]="notification.summary"
                                                    [nzPopoverTitle]="notification.title"
                                                    class="mb-3 cur-p"
                                                    nz-popover>
                                                    <div class="head">
                                                        <p class="title">{{notification.title}}</p>
                                                        <time class="time">{{notification.createdAt|timeAgo}}</time>
                                                    </div>
                                                    <!--
                                                    <p>{{notification.summary}}</p>
                                                    -->
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </perfect-scrollbar>
                                <div class="d-flex justify-content-between py-3">
                                    <button class="me-3" nz-button nz-tooltip nzSize="small"
                                            nzTooltipTitle="Tümünü göster"
                                            nzType="default">
                                        Tümünü Göster
                                    </button>
                                    <button (click)="markAllRead()" nz-button nz-tooltip nzSize="small"
                                            nzTooltipTitle="Tümünü Okundu Say"
                                            nzType="default"
                                    >
                                        Tümünü okundu işaretle
                                    </button>
                                </div>
                            </ng-container>
                            <ng-template #contentNoUnread>
                                <div class="text-center mb-3 py-4 bg-light rounded">Tebrikler!<br/>Tüm bildirimleri
                                    okumuşsunuz
                                </div>
                            </ng-template>
                        </nz-tab>
                        <nz-tab nzTitle="Görevler">
                            <div class="text-center mb-3 py-4 bg-light rounded">Tebrikler!<br/>Tüm görevleri
                                tamamlamışsınız
                            </div>
                        </nz-tab>
                    </nz-tabset>
                </div>
            </div>
        </div>
    </nz-dropdown-menu>
</div>
