import {Component, OnDestroy, OnInit} from '@angular/core'
import {Title} from '@angular/platform-browser'
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router'
import {SettingStore} from '@core/store/setting.store'
import {SettingInterface} from '@interfaces'
import {TranslateService} from '@ngx-translate/core'
import * as moment from 'moment-timezone'
import {filter, map, mergeMap} from 'rxjs/operators'
import {SubSink} from 'subsink'
import english from './locales/en-US'

import turkce from './locales/tr-TR'

const locales = {
    'tr-TR': turkce,
    'en-US': english
}

@Component({
    selector: 'app-root',
    template: `
        <ng-progress></ng-progress>
        <router-outlet></router-outlet>
    `
})
export class AppComponent implements OnInit, OnDestroy {
    _locale: string
    _theme: string
    _version: string
    title: string
    pageTitle: string = ''
    firstTouch: boolean = false
    settings: SettingInterface
    private subs = new SubSink()

    constructor(
        private settingStore: SettingStore,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        translate: TranslateService
    ) {
        moment.tz.setDefault('Europe/Istanbul')
        moment.locale('tr')

        Object.keys(locales).forEach(locale => {
            translate.setTranslation(locale, locales[locale])
        })
        translate.setDefaultLang('tr-TR')

        // localization && theme listener
        this.subs.add(
            this.settingStore.settingUpdate$.subscribe((settings: SettingInterface) => {
                this.settings = settings

                if (this._locale !== this.settings.locale) {
                    translate.use(this.settings.locale)
                }

                if (this._theme !== this.settings.theme) {
                    this.setTheme(this.settings.theme)
                }

                if (this._version !== this.settings.version) {
                    this.setVersion(this.settings.version)
                }

                this._locale = this.settings.locale
                this._theme = this.settings.theme
                this.title = this.settings.title
                this.setTitle()
            })
        )
    }

    ngOnDestroy() {
        this.subs.unsubscribe()
    }

    ngOnInit() {
        // set page title from router data variable
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map(route => {
                    while (route.firstChild) {
                        route = route.firstChild
                    }
                    return route
                }),
                map(route => {
                    while (route.firstChild) {
                        route = route.firstChild
                    }
                    return route
                }),
                filter(route => route.outlet === 'primary'),
                mergeMap(route => route.data)
            )
            .subscribe(event => {
                this.pageTitle = event['title']
                this.setTitle()
            })

        // TODO:test edilecek daha düzenli hale getirilecek gerekirse paket kullanılacak
        const detectViewPort = (load = false) => {
            const _isMobileView = window.innerWidth < 768
            const _isTabletView = window.innerWidth < 992
            const _isDesktopView = !_isMobileView && !_isTabletView
            const isMobileView = this.settingStore.get().isMobileView
            const isTabletView = this.settingStore.get().isTabletView
            const isDesktopView = !isMobileView && !isTabletView
            if (_isDesktopView && (_isDesktopView !== isDesktopView || load)) {
                this.settingStore.set('isMobileView', false)
                this.settingStore.set('isTabletView', false)
            }
            if (_isTabletView && !_isMobileView && (_isTabletView !== isTabletView || load)) {
                this.settingStore.set('isMobileView', false)
                this.settingStore.set('isTabletView', true)

                this.settingStore.set('isMenuCollapsed', true)
            }
            if (_isMobileView && (_isMobileView !== isMobileView || load)) {
                this.settingStore.set('isMobileView', true)
                this.settingStore.set('isTabletView', false)
            }
        }
        detectViewPort(true)
        window.addEventListener('resize', () => {
            detectViewPort()
        })

        // set primary color on app load
        const primaryColor = () => {
            const color = this.settingStore.get().primaryColor
            if (color) {
                const addStyles = () => {
                    const styleElement = document.querySelector('#primaryColor')
                    if (styleElement) {
                        styleElement.remove()
                    }
                    const body = document.querySelector('body')
                    const styleEl = document.createElement('style')
                    const css = document.createTextNode(`:root { --vb-color-primary: ${color};}`)
                    styleEl.setAttribute('id', 'primaryColor')
                    styleEl.appendChild(css)
                    body.appendChild(styleEl)
                }
                addStyles()

                this.settingStore.set('primaryColor', color)
            }
        }
        primaryColor()
    }

    // set title
    setTitle = () => {
        this.titleService.setTitle(`${this.title} | ${this.pageTitle}`)
    }

    // set version
    setVersion = version => {
        document.querySelector('html').setAttribute('data-vb-version', version)
    }

    // set theme
    setTheme = theme => {
        if (this.firstTouch) {
            document.querySelector('html').setAttribute('data-vb-theme', theme)
            if (theme === 'default') {
                this.settingStore.set('menuColor', 'white')
            }
            if (theme === 'dark') {
                this.settingStore.set('menuColor', 'dark')
            }
        }
        this.firstTouch = true
    }
}
