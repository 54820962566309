import {Directive, HostListener} from '@angular/core'
import {NgControl} from '@angular/forms'

@Directive({
    selector: '[formControlName][phoneMask]'
})
export class PhoneMaskDirective {
    constructor(public ngControl: NgControl) {
    }

    @HostListener('ngModelChange', ['$event'])
    onModelChange(event: any) {
        this.onInputChange(event, false)
    }

    @HostListener('keydown.backspace', ['$event'])
    keydownBackspace(event: any) {
        this.onInputChange(event.target.value, true)
    }

    onInputChange(event: any, backspace: any) {
        let newVal: any
        if (event === null) {
            newVal = null
        } else {
            newVal = event.replace(/\D/g, '')
            if (backspace && newVal.length <= 3) {
                newVal = newVal.substring(0, newVal.length - 1)
            }
            newVal = newVal.substring(0, 10)
            if (newVal.length === 0) {
                newVal = ''
            } else if (newVal.length <= 3) {
                newVal = newVal.replace(/^(\d{0,3})/, '($1)') // (532)
            } else if (newVal.length <= 6) {
                newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2') // (532) 555
            } else if (newVal.length <= 8) {
                newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,2})/, '($1) $2 $3') // (532) 555 66
            } else {
                newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})(\d{0,2})/, '($1) $2 $3 $4') // (532) 555 66 77
            }
        }
        this.ngControl.valueAccessor.writeValue(newVal)
    }
}
