import {Pipe, PipeTransform} from '@angular/core'
// import { CommonModule } from '@angular/common'
import * as moment from 'moment'

@Pipe({
    name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {
    transform(date: any, fromNow?: boolean): any {
        // TODO:fromNow true gelise default degeri false olmali
        return moment(date).fromNow(false)
    }
}
